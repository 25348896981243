// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "../pages/index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as EnrichedTextSectionCTA from "./EnrichedTextSectionCTA.bs.js";
import * as KontentContextProvider from "../pages/index/KontentContextProvider.bs.js";
import * as EnrichedTextSectionList from "./EnrichedTextSectionList.bs.js";
import * as EnrichedTextSectionImage from "./EnrichedTextSectionImage.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as EnrichedTextSectionNumberedList from "./EnrichedTextSectionNumberedList.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as UniqueSellingPoints__Description$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__Description.bs.js";

function section(aligmentStyle, distanceTop, distanceBottom) {
  return Curry._1(Css.style, {
              hd: Css.textAlign(aligmentStyle),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.marginTop(Belt_Option.isNone(distanceTop) ? Css.rem(0.75) : Css.px(Belt_Option.getWithDefault(distanceTop, 0))),
                      tl: {
                        hd: Css.marginBottom(Belt_Option.isNone(distanceBottom) ? Css.rem(2.5) : Css.px(Belt_Option.getWithDefault(distanceBottom, 0))),
                        tl: {
                          hd: Css.children({
                                hd: Css.lastChild({
                                      hd: Css.marginBottom(Css.zero),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var header = Curry._1(Css.style, {
      hd: Css.color(Theme__Color$PrezzeeRibbon.Business.charcoal),
      tl: {
        hd: Css.marginBottom(Css.px(14)),
        tl: {
          hd: Css.fontWeight("semiBold"),
          tl: {
            hd: Css.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
                  hd: Css.marginBottom(Css.zero),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                    hd: Css.marginBottom(Css.px(12)),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

function innerContainer(blockWidth, alignment) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems(alignment),
                  tl: {
                    hd: Css.width(Css.pct(blockWidth)),
                    tl: {
                      hd: Css.media(Theme__Responsive$PrezzeeRibbon.tabletOrMobile, {
                            hd: Css.width(Css.pct(100.0)),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.selector("a", {
                              hd: Css.color(Css.hex("1A65F0")),
                              tl: {
                                hd: Css.textDecoration("underline"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.color(Css.hex("1E54B7")),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.active({
                                          hd: Css.color(Css.hex("102C60")),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.visited({
                                            hd: Css.color(Css.hex("787878")),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var h1 = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(50)),
      tl: {
        hd: Css.lineHeight(Css.px(63)),
        tl: {
          hd: Css.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                hd: Css.fontSize(Css.px(32)),
                tl: {
                  hd: Css.lineHeight(Css.px(40)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var h2 = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(40)),
      tl: {
        hd: Css.lineHeight(Css.px(50)),
        tl: {
          hd: Css.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                hd: Css.fontSize(Css.px(32)),
                tl: {
                  hd: Css.lineHeight(Css.px(40)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var h3 = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(36)),
      tl: {
        hd: Css.lineHeight(Css.px(46)),
        tl: {
          hd: Css.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                hd: Css.fontSize(Css.px(32)),
                tl: {
                  hd: Css.lineHeight(Css.px(42)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var h4 = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(32)),
      tl: {
        hd: Css.lineHeight(Css.px(42)),
        tl: {
          hd: Css.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                hd: Css.fontSize(Css.px(28)),
                tl: {
                  hd: Css.lineHeight(Css.px(38)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var LocalStyles = {
  section: section,
  header: header,
  innerContainer: innerContainer,
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4
};

function EnrichedTextSection(props) {
  var block = props.block;
  var elements = block.content.elements;
  var width = block.width;
  var alignment = block.alignment;
  var distanceTop = block.distance_top;
  var distanceBottom = block.distance_bottom;
  var itemId = block.smartLink.itemId;
  var elementCodename = block.smartLink.codename;
  var f = function (idx, item) {
    var key = String(idx);
    var data = item.data;
    var tag = item.tag;
    switch (tag) {
      case "CTA" :
          return JsxRuntime.jsx(EnrichedTextSectionCTA.make, {
                      data: data
                    }, key);
      case "H1" :
          return JsxRuntime.jsx("h1", {
                      className: Cn.make([
                            h1,
                            header
                          ]),
                      dangerouslySetInnerHTML: {
                        __html: data
                      }
                    }, key);
      case "H2" :
          return JsxRuntime.jsx("h2", {
                      className: Cn.make([
                            h2,
                            header
                          ]),
                      dangerouslySetInnerHTML: {
                        __html: data
                      }
                    }, key);
      case "H3" :
          return JsxRuntime.jsx("h3", {
                      className: Cn.make([
                            h3,
                            header
                          ]),
                      dangerouslySetInnerHTML: {
                        __html: data
                      }
                    }, key);
      case "H4" :
          return JsxRuntime.jsx("h4", {
                      className: Cn.make([
                            h4,
                            header
                          ]),
                      dangerouslySetInnerHTML: {
                        __html: data
                      }
                    }, key);
      case "IMAGE" :
          return JsxRuntime.jsx(EnrichedTextSectionImage.make, {
                      data: data
                    }, key);
      case "OL" :
          return JsxRuntime.jsx(EnrichedTextSectionNumberedList.make, {
                      data: data
                    }, key);
      case "P" :
          return JsxRuntime.jsx(UniqueSellingPoints__Description$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx("p", {
                            dangerouslySetInnerHTML: {
                              __html: data
                            }
                          })
                    }, key);
      case "UL" :
          return JsxRuntime.jsx(EnrichedTextSectionList.make, {
                      data: data
                    }, key);
      default:
        return null;
    }
  };
  var blockWidth;
  switch (width) {
    case "n10_columns" :
        blockWidth = 83.0;
        break;
    case "n12_columns" :
        blockWidth = 100.0;
        break;
    case "n6_columns" :
        blockWidth = 50.0;
        break;
    case "n8_columns" :
        blockWidth = 66.0;
        break;
    default:
      blockWidth = 100.0;
  }
  var aligmentStyle;
  switch (alignment) {
    case "center" :
        aligmentStyle = "center";
        break;
    case "right" :
        aligmentStyle = "right";
        break;
    default:
      aligmentStyle = "left";
  }
  var flexAlignment;
  switch (alignment) {
    case "center" :
        flexAlignment = "center";
        break;
    case "right" :
        flexAlignment = "flexEnd";
        break;
    default:
      flexAlignment = "flexStart";
  }
  var content = Belt_Array.mapWithIndex(elements, f);
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: props.wallOfCards !== undefined ? JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  children: content,
                                  className: innerContainer(blockWidth, flexAlignment)
                                }),
                            className: Cn.make([
                                  section(aligmentStyle, distanceTop, distanceBottom),
                                  "mx-4"
                                ])
                          }) : JsxRuntime.jsx("section", {
                            children: JsxRuntime.jsx("div", {
                                  children: content,
                                  className: innerContainer(blockWidth, flexAlignment)
                                }),
                            className: Cn.make([
                                  IndexStyles.section,
                                  section(aligmentStyle, distanceTop, distanceBottom)
                                ])
                          })
                  }),
              itemId: itemId,
              elementCodename: elementCodename
            });
}

var UniqueSellingPoints;

var Description;

var KontentContextConsumer;

var make = EnrichedTextSection;

export {
  UniqueSellingPoints ,
  Description ,
  KontentContextConsumer ,
  LocalStyles ,
  make ,
}
/* header Not a pure module */
