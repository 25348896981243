// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import Link from "next/link";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Gql__CallToAction__Decoder from "../../api/graphql/CallToAction/Gql__CallToAction__Decoder.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";

var button = Curry._1(BsCss.style, {
      hd: BsCss.marginBottom(BsCss.px(23)),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
              hd: BsCss.marginBottom(BsCss.px(28)),
              tl: /* [] */0
            }),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                hd: BsCss.marginBottom(BsCss.px(18)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var LocalStyles = {
  button: button
};

function EnrichedTextSectionCTA(props) {
  var parsedData = JSON.parse(props.data);
  var match = Gql__CallToAction__Decoder.parse_cta_style(parsedData.cta_style__style);
  var target = parsedData.open_in_new_window ? "_blank" : "";
  var href = parsedData.cta_url;
  return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
              children: JsxRuntime.jsx(Link, {
                    href: href,
                    prefetch: false,
                    children: JsxRuntime.jsx("a", {
                          children: JsxRuntime.jsx("button", {
                                children: parsedData.cta_text
                              }),
                          target: target
                        })
                  }),
              fill: match[2],
              colour: match[1],
              variant: match[0],
              className: button
            });
}

var make = EnrichedTextSectionCTA;

export {
  LocalStyles ,
  make ,
}
/* button Not a pure module */
