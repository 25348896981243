// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CmsImage from "../CmsImage.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as KontentContextProvider from "../pages/index/KontentContextProvider.bs.js";

function EnrichedTextSectionImage(props) {
  var parsedData = JSON.parse(props.data);
  var src = parsedData.url;
  var alt = parsedData.alt;
  var width = parsedData.width;
  var height = parsedData.height;
  var itemId = parsedData.id;
  var elementCodename = parsedData.codename;
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(CmsImage.make, {
                    src: src,
                    alt: alt,
                    width: width,
                    height: height,
                    className: "w-full mb-2",
                    sizes: "(max-width: 1440px) 100vw, 1440px"
                  }),
              itemId: itemId,
              elementCodename: elementCodename
            });
}

var make = EnrichedTextSectionImage;

export {
  make ,
}
/* CmsImage Not a pure module */
