// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ParseRichTextData from "../../utils/parseRichTextData";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as UniqueSellingPoints__List$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__List.bs.js";
import * as UniqueSellingPoints__ListItem$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/UniqueSellingPoints/UniqueSellingPoints__ListItem.bs.js";

var list = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexDirection("column"),
        tl: {
          hd: BsCss.textAlign("left"),
          tl: {
            hd: BsCss.marginBottom(BsCss.px(23)),
            tl: {
              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
                    hd: BsCss.marginBottom(BsCss.px(28)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                      hd: BsCss.marginBottom(BsCss.px(18)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var LocalStyles = {
  list: list
};

function parseList(prim) {
  return ParseRichTextData.parseList(prim);
}

function EnrichedTextSectionList(props) {
  var items = ParseRichTextData.parseList(props.data);
  var f = function (idx, item) {
    var key = String(idx);
    return JsxRuntime.jsx(UniqueSellingPoints__ListItem$PrezzeeRibbon.make, {
                children: JsxRuntime.jsx("span", {
                      dangerouslySetInnerHTML: {
                        __html: item
                      }
                    })
              }, key);
  };
  var content = Belt_Array.mapWithIndex(items, f);
  return JsxRuntime.jsx(UniqueSellingPoints__List$PrezzeeRibbon.make, {
              children: JsxRuntime.jsx("div", {
                    children: content
                  }),
              className: list
            });
}

var UniqueSellingPoints;

var List;

var ListItem;

var make = EnrichedTextSectionList;

export {
  UniqueSellingPoints ,
  List ,
  ListItem ,
  LocalStyles ,
  parseList ,
  make ,
}
/* list Not a pure module */
